<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'yichangAuditList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="单号" name="name">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.name" placeholder="请输入订单号或第三方单号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="寄件人" name="send_tel">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.send_tel" placeholder="请输入寄件人姓名或手机号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="收件人" name="receive_tel">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.receive_tel" placeholder="请输入收件人姓名或手机号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态" name="status">
              <a-select mode="multiple" v-model:value="queryParam.status" placeholder="请选择">
                <a-select-option :value="1">已完成</a-select-option>
                <a-select-option :value="2">待完成</a-select-option>
                <a-select-option :value="3">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="特别备注" name="remark">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.remark" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="navlist">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd" style="margin-left: 10px">新建邮寄单</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'order_number'">
          <p>
            订单号：{{ record.order_number }} <a-tag color="green"> {{ record.pay_type_name }} </a-tag>
          </p>
          <p>第三方订单号：{{ record.order_number }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'receive'">
          <p>姓名：{{ record.receive_consignee_name }}</p>
          <p>手机号：{{ record.receive_tel }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'send'">
          <p>姓名：{{ record.send_consignee_name }}</p>
          <p>手机号：{{ record.send_tel }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'status'">
          <a-tag color="green" v-if="record.status == 1"> 已完成 </a-tag>
          <a-tag color="red" v-else-if="record.status == 2"> 待完成 </a-tag>
          <a-tag color="gray" v-else-if="record.status == 3"> 已取消 </a-tag>
        </template>
        <template v-else-if="column.dataIndex === 'created_at'">
          <p>{{ record.created_at }}</p>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </template>
    </a-table>
    <!-- 新增客户 -->
    <create-form ref="createForm" :key="data.createKey" :open="data.visible" @cancel="handleCancel" @ok="handleOk" />
    <edit-form v-if="data.editVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
  </a-card>
</template>
<script lang="ts" setup name="yichangAuditList">
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateOtherForm.vue'
import EditForm from './CreateOtherForm.vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { costByPoints } from '@logicflow/core/types/util'
import { useStore } from 'vuex'
const $store = useStore()
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'

interface data {
  visible: boolean
  editVisible: boolean //编辑弹窗
  loading: boolean
  tableColumnsData: Object //传数据的
  createKey: number //添加的key
}
const data = reactive<data>({
  visible: false,
  editVisible: false,
  loading: false,
  tableColumnsData: {},
  createKey: 1
})
const createForm = ref()
const columns = [
  {
    title: '单号',
    dataIndex: 'order_number'
  },
  {
    title: '收件人',
    dataIndex: 'receive'
  },
  {
    title: '发件人',
    dataIndex: 'send'
  },
  {
    title: '状态',
    dataIndex: 'status'
  },
  {
    title: '其他',
    dataIndex: 'created_at'
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 200
  }
]
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/otherLogisticList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, customer_id: item.id }
      })
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('异常审核列表查询失败,请联系管理员')
      }
    })
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}

// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

//右上角的添加和编辑
const handleAdd = () => {
  data.tableColumnsData = {}
  data.visible = true
}

const handleEdit = (record) => {
  data.editVisible = true
  data.tableColumnsData = { ...record }
}
const handleOk = () => {
  data.createKey++
  data.visible = false
  data.editVisible = false
  loadData()
}
const handleCancel = () => {
  data.visible = false
  data.editVisible = false
}

const verificatioChange = (record, type) => {
  let requestParam = {
    url: '/admin/v1/attrAdjustAudit',
    method: 'post',
    data: {
      reason: record.desc,
      status: type,
      id: record.id
    }
  }
  baseService(requestParam)
    .then((res) => {
      message.success('操作成功')
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}
</script>
<style lang="less" scoped>
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
</style>
