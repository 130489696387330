<template>
  <a-modal
    :title="`${props.tableColumnsData.full_name}控制台`"
    :width="1300"
    :open="true"
    okText="确定通知资料员做单"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
    v-if="data.success_type"
  >
    <a-form @finish="onFinish" v-bind="formLayout" :model="formState">
      <a-form-item label="核验方式">
        <a-radio-group v-model:value="type" button-style="solid">
          <a-radio-button :value="1">自动核验</a-radio-button>
          <a-radio-button :value="2">支付宝核验</a-radio-button>
          <a-radio-button :value="3">其他资料核验</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-table :loading="data.ownershipTableLoading" v-show="type == 1" style="margin-bottom: 30px" :columns="columns" rowKey="fullname" :data-source="shareOwnershipTableData" :pagination="false">
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'fullname'">
            <span>{{ record.fullname || record.name }}</span>
          </template>
          <template v-else-if="column.dataIndex === 'id_card_number'">
            <div>{{ record.id_card_number }}</div>
            <div>{{ record.address }}</div>
            <a-button v-if="!record.showIdCard" @click="record.showIdCard = true" style="display: block; padding: 0" type="link">查看身份证照片</a-button>
            <div v-if="record.showIdCard">
              <a-image :width="100" :src="$store.state.user.image_domain + record.id_card_back"></a-image>
              <a-image :width="100" :src="$store.state.user.image_domain + record.id_card_front"></a-image>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'phone_number'">
            <div v-if="record.isEdit">
              <a-input maxLength="11" style="width: 130px; margin-bottom: 5px" v-model:value="record.phone_number" placeholder="请输入手机号码" name="phone_number"></a-input>
              <div>
                <a-button class="mr-2x" @click="queryDetail()" size="small">取消</a-button>
                <a-button @click="editPhoneChange(record)" size="small" type="primary">确认</a-button>
              </div>
            </div>
            <span v-else @click="editPhone(index)" class="pointer phone_number"
              >{{ record.phone_number || '无' }}
              <EditOutlined />
            </span>
          </template>
          <template v-else-if="column.dataIndex === 'sign_status'">
            <span style="display: flex; align-items: center" v-if="record.is_sign_show == 1">
              <a-tag :color="text == '未签字' ? 'red' : 'cyan'"> {{ text }} </a-tag>
              <a-image v-if="text == '已签字'" :width="70" :src="record.signature_url" />
              <a-popconfirm v-if="text == '已签字'" title="确定退回签字?" @confirm="backSignature(record)">
                <a v-if="text == '已签字'">退回签字</a>
              </a-popconfirm>
              <a-popover v-else>
                <template #content>
                  <div class="m-x">
                    <a-qrcode :bordered="false" :value="'http://console.cskaiyela.com/m/pages/signature/index?id=' + props.tableColumnsData.id" />
                  </div>
                </template>
                <a>签字</a>
              </a-popover>
            </span>
            <span v-else> - </span>
          </template>
          <template v-else-if="column.dataIndex === 'hyflag'">
            <span>
              <a-tag color="red" v-if="text == 0"> 未核验 </a-tag>
              <a-tag color="cyan" v-else> 核验成功({{ text }}) </a-tag>
            </span>
          </template>
          <template v-else-if="column.dataIndex === 'position_ids'">
            <div v-if="record.joblist && record.joblist.length > 0">
              <a-tag class="positionItem" :color="item.name == '法定代表人' ? 'red' : 'cyan'" v-for="(item, index) in record.joblist" :key="index">
                {{ item.name }}
              </a-tag>
            </div>
            <div v-else>无</div>
          </template>
          <template v-else-if="column.dataIndex === 'options'">
            <a-popover title="核验操作" placement="left" trigger="click" overlayClassName="order-verification-popover" @openChange="(val) => onVerificationClick(val, record, index)">
              <template #content>
                <a-spin tip="更新数据中" :spinning="verificationLoading">
                  <a-table style="width: 700px" :columns="verificationColumns" rowKey="id" :pagination="false" :data-source="heyanBenrentelData">
                    <template #bodyCell="{ column, record, index, text }">
                      <template v-if="column.dataIndex == 'mobile'">
                        {{ record.text }}
                        {{ record.mobile }}
                        <a-popover trigger="click" overlayClassName="customer-options-popover">
                          <template #content>
                            <a-table style="width: 400px" :columns="affiliatedCompanyColumns" rowKey="id" :pagination="false" :data-source="record.affiliatedCompany">
                              <template #bodyCell="{ column1, record1, index1, text1 }"> </template>
                            </a-table>
                          </template>
                          <span
                            ><a v-if="record.affiliatedCompany_len > 0"> 查看关联（{{ record.affiliatedCompany_len }}）</a></span
                          >
                        </a-popover>
                      </template>
                      <template v-if="column.dataIndex == 'res_status'">
                        <a-tag color="cyan" v-if="record.res_status == 1"> 通过√ </a-tag>
                        <a-tag color="red" v-else> 未通过× </a-tag>
                      </template>
                    </template>
                  </a-table>
                  <div v-if="telusedminrequired > 0 && heyanBenrentelData.length > 0" style="display: flex; justify-content: end; align-items: center; margin-top: 70px">
                    <!-- <div v-if="verificationDisabled()">
                      <a-tag color="red">实名核验有误不能核验手机号码使用时间</a-tag>
                    </div>
                    <div v-else> -->
                    <a-button type="primary" @click="onVerificationPhoneClick(record, index)"> 核验手机号码使用时间 </a-button>
                    (手机号需要使用{{ telusedminrequired }}月以上)
                    <!-- </div> -->
                  </div>
                </a-spin>
              </template>
              <a>核验</a>
            </a-popover>
          </template>
        </template>
      </a-table>
      <a-form-item label="说明" v-show="type == 2">
        <span>支付宝搜索</span>
      </a-form-item>

      <div v-if="type == 3" :key="data.key">
        <a-form-item label="核验资料说明">
          <div>
            <a-textarea class="mb-1x" v-model:value="formState.statement" placeholder="请输入核验资料说明" auto-size style="width: 400px" />
            <a @click="onHistoryClick" style="margin-left: 20px; line-height: 50px">查看历史审核记录</a>
          </div>
          <div>
            <a
              @click="
                () => {
                  formState.statement = '代理记账合同'
                }
              "
              class="mr-2x"
              >代理记账合同</a
            >
            <a
              @click="
                () => {
                  formState.statement = '手持承诺书拍照'
                }
              "
              >手持承诺书拍照</a
            >
          </div>
        </a-form-item>
        <a-form-item label="其他资料核验">
          <Upload
            :upload_path="props.tableColumnsData.upload_path"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'mp4']"
            @uploading="
              (val) => {
                data.finishLoading = val
              }
            "
            :customerName="props.tableColumnsData.entityNameToRegister.full_name"
            uploadType="text"
            v-model:value="formState.urls"
            :limitation="99"
            uploadText="上传其他资料"
          >
          </Upload
          ><span>说明：其他资料可以是代理记账合同，或者其他你认为可以消除疑点的资料，只要确定他不是卖发票的，不是洗钱的动机即可，整体来讲就是要排除此次注册行为，消除疑点即可。</span>
          <div>
            <p>以下方式，任选其一：</p>
            <p>1：采用手持承诺书过风控； <a @click="onDocxClick">范本.docx</a><span>要求看到人的半身，左右手臂完全入镜</span></p>
            <p>2：拍摄视频验证； 大致差不多即可。文案："<span style="color: #1677ff">我承诺办理的长沙xxxx公司营业执照，是本人真实意愿的表达，合法经营，特此声明</span>"</p>
          </div>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 7, span: 16 }">
          <a-button type="primary" class="mr-2x" :loading="data.finishLoading" :disabled="data.finishLoading" html-type="submit">提交审核</a-button>
        </a-form-item>
        <a-table
          v-if="false"
          :loading="data.verificationTableLoading"
          style="margin-bottom: 30px"
          :columns="verificationDataColumns"
          rowKey="fullname"
          :data-source="data.verificationData"
          :pagination="false"
        >
          <template #bodyCell="{ column, record, index, text }">
            <template v-if="column.dataIndex === 'fullname'">
              <span>{{ record.fullname || record.name }}</span>
            </template>
            <template v-else-if="column.dataIndex === 'id_card_number'">
              <span>{{ record.id_card_number }}</span>
            </template>
          </template>
        </a-table>
      </div>
    </a-form>
    <div class="remark">核验说明: {{ props.tableColumnsData.remark ? props.tableColumnsData.remark : '无核验说明' }}</div>
    <a-modal :title="`${props.tableColumnsData.full_name}变更登录`" :keyboard="false" :maskClosable="false" :width="800" :open="data.openQrcode" :footer="null" @cancel="qrcodeCancel">
      <a-form @finish="onRegisterFinish" v-bind="formLayout" :model="registerFormState" :rules="rules">
        <a-form-item label="核验方式">
          <a-radio-group v-model:value="registerFormState.type" button-style="solid">
            <a-radio :value="1">短信验证码登录</a-radio>
            <a-radio :value="2">授权登录</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="扫码登录" v-show="registerFormState.type == 2">
          <div class="d-flex flex-center" v-show="data.deadline > 0">
            <img :src="data.qrcode" />
            <a-statistic-countdown format="mm:ss" title="登录倒计时" :value="data.deadline" @finish="onStatisticFinish" />
          </div>
          <div v-show="data.deadline == 0">
            <a-button type="primary" @click="onFormSubmit">重新获取</a-button>
          </div>
        </a-form-item>
        <div v-if="registerFormState.type == 1">
          <a-form-item label="统一社会信用代码" name="social_credit_code">
            <a-input v-model:value="registerFormState.social_credit_code" placeholder="请输入统一社会信用代码"> </a-input>
          </a-form-item>
          <a-form-item label="联络员姓名" name="fullname">
            <a-input v-model:value="registerFormState.fullname" placeholder="请输入联络员姓名"> </a-input>
          </a-form-item>
          <a-form-item label="联络员身份证" name="id_card_number">
            <a-input v-model:value="registerFormState.id_card_number" placeholder="请输入联络员身份证"> </a-input>
          </a-form-item>
          <a-form-item label="联络员电话号码" name="phone_number">
            <div class="d-flex">
              <a-input v-model:value="registerFormState.phone_number" placeholder="请输入联络员电话号码"> </a-input>
              <a-button type="primary" class="ml-1x" @click="onGetCodeClick" :disabled="data.getCodeDisabled">获取验证码</a-button>
            </div>
          </a-form-item>
          <a-form-item label="验证码">
            <a-input v-model:value="registerFormState.code" placeholder="请输入验证码"> </a-input>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 7, span: 16 }">
            <a-button type="primary" html-type="submit">确定</a-button>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
    <a-modal title="历史审核记录" :width="1200" :bodyStyle="{ padding: '30px' }" :open="data.openHistory" :footer="null" @cancel="historyCancel">
      <a-table :loading="data.historyLoading" :columns="historyColumns" :data-source="data.historyList" :pagination="false">
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'name'">
            {{ record.customer_info?.full_name }}
          </template>
          <template v-else-if="column.dataIndex === 'verification'">
            <a-tag color="blue" v-if="record.status_name == '待审核'">{{ record.status_name }}</a-tag>
            <a-tag color="green" v-else-if="record.status_name == '审核通过'">{{ record.status_name }}</a-tag>
            <a-tag color="red" v-else>{{ record.status_name }}</a-tag>
            <div v-if="record.reason">理由:{{ record.reason }}</div>
          </template>
          <template v-else-if="column.dataIndex === 'statement'">
            <div style="max-width: 200px">
              {{ text?.length > 0 ? text : '-' }}
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'updated_at'">
            <div v-if="record.status_name != '待审核'">{{ record.operator_admin_info?.nickname }}{{ text }}审核完毕,耗时{{ calculateTimeDifference(record.created_at, record.updated_at) }}</div>
            <div v-else>-</div>
          </template>
          <template v-else-if="column.dataIndex === 'urls'">
            <div style="flex-wrap: wrap; gap: 10px 10px" class="d-flex" v-if="text?.length > 0">
              <span v-for="(item, index) in text">
                <a-image
                  v-if="item.name.indexOf('jpg') > -1 || item.name.indexOf('png') > -1 || item.name.indexOf('jpeg') > -1"
                  :key="index"
                  :width="100"
                  :height="100"
                  :src="$store.state.user.image_domain + item.url"
                >
                </a-image>
                <p v-else>
                  <a :href="$store.state.user.image_domain_a + item.url" download target="_blank">{{ item.name }}</a>
                </p>
              </span>
            </div>
            <div v-else>-</div>
          </template>
        </template>
      </a-table>
    </a-modal>
    <a-modal
      :open="data.openPushOrder"
      @cancel="
        () => {
          data.openPushOrder = false
        }
      "
      @ok="handlePushOrder"
      :confirmLoading="data.confirmLoading"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
      okText="立马提交"
    >
      <template #title>
        <div class="modal-title">
          <InfoCircleOutlined class="InfoCircleOutlined" />
          <span class="title"> 确定通知资料员做单 </span>
        </div>
      </template>
      <p>立马提交：则电子化状态为材料检查中或待签名</p>
    </a-modal>
  </a-modal>
  <a-modal :width="1300" :open="true" v-if="!data.success_type" :footer="null">
    <SuccessPage
      :data="data.success_type"
      :tableColumnsData="data.success_type"
      @cancel="
        () => {
          data.success_type = !data.success_type
        }
      "
      @ok="
        () => {
          emit('cancel')
        }
      "
    ></SuccessPage>
  </a-modal>
</template>
<script lang="ts" setup>
import { reactive, onMounted, toRefs, h, ref } from 'vue'
import Upload from '@/components/base/Upload.vue'
import { message, Modal } from 'ant-design-vue'
import { EditOutlined } from '@ant-design/icons-vue'
import baseService from '@/utils/http/axios'
import { mobileRequiredValidator } from '@/utils/util'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import SuccessPage from '../../exception/200.vue'
import dayjs from 'dayjs'
const $store = useStore()
interface tableData {
  isEdit: boolean
}
let timer = ref()
//关闭清空
const qrcodeCancel = () => {
  data.openQrcode = false
  clearInterval(timer.value)
}

const rules = {}
const emit = defineEmits(['cancel', 'ok'])
const verificationDataColumns = [
  {
    title: '状态',
    dataIndex: 'fullname'
  },
  {
    title: '审核时间',
    dataIndex: 'fullname'
  },
  {
    title: '创建时间',
    dataIndex: 'fullname'
  },
  {
    title: '申请人',
    dataIndex: 'fullname'
  }
]
const historyColumns = [
  {
    title: '公司名称',
    dataIndex: 'name',
    width: 150
  },
  {
    title: '审核',
    dataIndex: 'verification',
    width: 150
  },
  {
    title: '其他资料',
    dataIndex: 'urls'
  },
  {
    title: '备注',
    dataIndex: 'statement',
    width: 200
  },
  {
    title: '申请时间',
    dataIndex: 'created_at',
    width: 150
  },
  {
    title: '审核时间',
    dataIndex: 'updated_at',
    width: 150
  }
]
const columns: Array<Object> = [
  {
    title: '姓名',
    dataIndex: 'fullname'
  },
  {
    title: '身份证号码',
    dataIndex: 'id_card_number'
  },
  {
    title: '担任职位',
    dataIndex: 'position_ids',
    width: '200px'
  },
  {
    title: '手机号码',
    dataIndex: 'phone_number',
    width: '180px'
  },

  {
    title: '签字状态',
    dataIndex: 'sign_status'
  },
  {
    title: '签字时间',
    dataIndex: 'sign_time'
  },
  {
    title: '核验状态',
    dataIndex: 'hyflag',
    width: '100px'
  },
  {
    title: '操作',
    dataIndex: 'options',
    width: 80
  }
]
const verificationColumns: Object[] = [
  {
    title: '手机号码',
    dataIndex: 'mobile',
    width: 220
  },
  {
    title: '核验详情',
    dataIndex: 'resmsg'
  },
  {
    title: '核验结果',
    dataIndex: 'res_status',
    scopedSlots: { customRender: 'res_status' }
  },
  {
    title: '核验人',
    dataIndex: 'author'
  },
  {
    title: '核验时间',
    dataIndex: 'ctime'
  }
]
const affiliatedCompanyColumns: Object[] = [
  {
    title: '公司名称',
    dataIndex: 'full_name'
  }
]
const formLayout: Object = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
const formState = reactive<any>({
  statement: null,
  urls: null
})
const registerFormState = reactive<any>({
  code: null,
  fullname: null,
  id_card_number: null,
  phone_number: null,
  type: 1,
  id: null
})
interface data {
  telusedminrequired: number
  verificationLoading: boolean
  verificationTableLoading: boolean
  confirmLoading: boolean
  ownershipTableLoading: boolean
  type: number
  qrcode: string
  openQrcode: boolean
  openHistory: boolean
  historyLoading: boolean
  deadline: number
  key: number
  verificationData: any[]
  historyList: any[]
  getCodeDisabled: boolean
  finishLoading: boolean
  openPushOrder: boolean
  success_type: boolean
}
const data = reactive<data>({
  telusedminrequired: 0,
  verificationLoading: false,
  confirmLoading: false,
  verificationTableLoading: false,
  ownershipTableLoading: false,
  type: 1,
  qrcode: '',
  openQrcode: false,
  openHistory: false,
  deadline: 0,
  key: 1,
  historyLoading: false,
  verificationData: [],
  historyList: [],
  getCodeDisabled: false,
  finishLoading: false,
  openPushOrder: false,
  success_type: true
})
let heyanBenrentelData = reactive<[]>([])
let affiliatedCompany = reactive<[]>([])
const shareOwnership = ref<tableData[]>([])
let shareOwnershipTableData = shareOwnership.value
const { telusedminrequired, verificationLoading, type } = toRefs(data)

onMounted(() => {
  queryDetail()
  if (props.tableColumnsData.business_type != 1 && props.tableColumnsData.main_type == 1) {
    // getMigrateSmsCode()
  }
})
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const backSignature = (record) => {
  let data: any = {
    customer_id: props.tableColumnsData.id,
    id: record.stockholder_id ? record.stockholder_id : record.persion_id
  }
  let requestParam = {
    url: '/admin/v1/signatureBack',
    method: 'post',
    data: data
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      queryDetail()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

// 计算时间差
const calculateTimeDifference = (startDate: string, endDate: string) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  const duration = end.diff(start, 'minute')
  return formatDuration(duration)
}
const formatDuration = (duration: number) => {
  const days = Math.floor(duration / (24 * 60))
  const hours = Math.floor((duration % (24 * 60)) / 60)
  const minutes = duration % 60
  return `${days > 0 ? days + '天' : ''}${hours > 0 ? hours + '小时' : ''}${minutes > 0 ? minutes : 1}分钟`
}

const getMigrateSmsCode = () => {
  data.ownershipTableLoading = true
  let requestParam = {
    url: '/admin/v1/getLiaisonInfo?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      registerFormState.social_credit_code = props.tableColumnsData.social_credit_code
      registerFormState.id = res.data.id
      registerFormState.fullname = res.data.fullname
      registerFormState.id_card_number = res.data.id_card_number
      registerFormState.phone_number = res.data.phone_number
      if (res.data.is_change) {
        message.warn('检测到联络员有变化,正在重新刷新列表')
        queryDetail()
      }
    })
    .catch((res) => {})
}
const historyCancel = () => {
  data.openHistory = false
}
const onHistoryClick = () => {
  data.openHistory = true
  data.historyLoading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerDataVerificatioList',
    method: 'post',
    data: Object.assign({ page: 1, limit: 'all', name: props.tableColumnsData.full_name })
  }
  baseService(requestParam)
    .then((res) => {
      data.historyLoading = false
      data.historyList = res.data.list
    })
    .catch((res) => {
      data.historyLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('审核列表查询失败,请联系管理员')
      }
    })
}

const onStatisticFinish = () => {
  // data.openQrcode = false
  data.qrcode = ''
  data.deadline = 0
}
const queryDetail = () => {
  return new Promise((resolve, reject) => {
    data.ownershipTableLoading = true
    let requestParam = {
      url: '/admin/v1/tuisongzuodan',
      method: 'post',
      data: {
        action: 'getgudonglist',
        id: props.tableColumnsData.id
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        let list: tableData[] = res.data.map((item: any) => ({
          ...item,
          isEdit: false,
          showIdCard: false
        }))
        shareOwnership.value = [...list]
        shareOwnershipTableData = shareOwnership.value
        data.ownershipTableLoading = false
        resolve(shareOwnershipTableData)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  })
}
const onGetCodeClick = () => {
  data.getCodeDisabled = true
  let requestParam = {
    url:
      '/admin/v1/getMigrateSmsCode?id_card_number=' +
      registerFormState.id_card_number +
      '&social_credit_code=' +
      registerFormState.social_credit_code +
      '&customer_id=' +
      props.tableColumnsData.id +
      '&phone_number=' +
      registerFormState.phone_number +
      '&fullname=' +
      registerFormState.fullname,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
    })
    .catch((res) => {
      data.getCodeDisabled = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取失败')
      }
    })
}
const handlePushOrder = () => {
  data.confirmLoading = true
  let requestParam = {
    url: '/admin/v1/pushOrder',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      data.confirmLoading = false
      message.success(res.msg)
      emit('ok')
    })
    .catch((res) => {
      data.confirmLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const onFinish = () => {
  if (formState.statement || formState.urls?.length > 0) {
    data.finishLoading = true
    let requestParam = {
      url: '/admin/v1/customerDataVerificatio',
      method: 'post',
      data: {
        customer_id: props.tableColumnsData.id,
        urls: formState.urls,
        statement: formState.statement
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        data.finishLoading = false
        data.key = data.key + 1
        formState.urls = []
        formState.statement = ''
        message.success(res.msg)
        data.success_type = false
      })
      .catch((res) => {
        data.finishLoading = false
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  } else {
    message.error('请上传其他资料或者填写备注')
  }
}
// 提交验证码
const onRegisterFinish = () => {
  if (!registerFormState.code) {
    message.error('请输入验证码')
    return
  }
  let requestParam = {
    url: '/admin/v1/setMigrateSmsCode',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id,
      verify_code: registerFormState.code,
      fullname: registerFormState.fullname,
      phone_number: registerFormState.phone_number,
      id_card_number: registerFormState.id_card_number,
      social_credit_code: registerFormState.social_credit_code
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      qrcodeCancel()
      let requestParam = {
        url: '/admin/v1/pushOrder',
        method: 'post',
        data: {
          customer_id: props.tableColumnsData.id
        }
      }
      baseService(requestParam)
        .then((res: any) => {
          message.success(res.msg)
          emit('ok')
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const onFormSubmit = () => {
  if (props.tableColumnsData.registration_type == 2) {
    message.error('该客户为裸地址，不支持推送做单')
    return
  }
  if (props.tableColumnsData.is_auto == 2) {
    message.error('该客户已关闭自动提交，不支持推送做单')
    return
  }
  let promise = queryDetail()
  promise.then((res: any) => {
    let queryParam = {
      url: '/admin/v1/getDataVerificatioStatus?customer_id=' + props.tableColumnsData.id,
      method: 'get'
    }
    baseService(queryParam).then((val: any) => {
      if (val.data.status == 1) {
        modalConfirm()
      } else {
        let isHyflag = false
        res.forEach((item, index) => {
          if (item.hyflag >= 2) {
            isHyflag = true
          }
        })
        if (!isHyflag) {
          message.error('有未核验的股东，请先核验')
          return
        }
        modalConfirm()
      }
    })
  })
}
const modalConfirm = () => {
  if (props.tableColumnsData.business_type == 1) {
    data.openPushOrder = true
  } else {
    let param = {
      url: '/admin/v1/getAlterationQrcodeStatus?customer_id=' + props.tableColumnsData.id,
      method: 'get'
    }
    baseService(param)
      .then((res: any) => {
        if (res.data.status == 1) {
          data.openPushOrder = true
        } else {
          let queryParam = {
            url: '/admin/v1/getAlterationQrcodeUrl?customer_id=' + props.tableColumnsData.id,
            method: 'get'
          }
          baseService(queryParam)
            .then((res: any) => {
              timer.value = setInterval(() => {
                let param = {
                  url: '/admin/v1/getAlterationQrcodeStatus?customer_id=' + props.tableColumnsData.id,
                  method: 'get'
                }
                baseService(param).then((res: any) => {
                  if (res.data.status == 1) {
                    qrcodeCancel()
                    data.openPushOrder = true
                  }
                })
              }, 3000)
              data.openQrcode = true
              data.deadline = Date.now() + 1000 * 60
              data.qrcode = res.data.base64_image
            })
            .catch((res) => {
              if (res.msg) {
                message.error(res.msg)
              } else {
                message.error('操作失败')
              }
            })
        }
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  }
}
const onDocxClick = () => {
  //window.open('https://pan.baidu.com/s/18Mjh_XWTcwUY01_SbGiJfA?pwd=8888')
  window.open('https://hncs.cskaiyela.com/storage/public/uploads/模板资料/手持承诺书拍照范本.docx')
}
const editPhone = (index: number) => {
  shareOwnershipTableData[index].isEdit = true
}

// 修改手机号码
const editPhoneChange = (record) => {
  let phone = record.phone_number_faren ? record.phone_number_faren : record.phone_number
  let promise = mobileRequiredValidator({}, phone)
  promise
    .then(() => {
      let data: any = {
        action: 'changephone',
        phone_number: phone
      }
      if (record.stockholder_id) {
        data.stockholder_id = record.stockholder_id
      }
      if (record.persion_id) {
        data.persion_id = record.persion_id
      }
      let requestParam = {
        url: '/admin/v1/tuisongzuodan',
        method: 'post',
        data: data
      }
      baseService(requestParam)
        .then((res: any) => {
          message.success(res.msg)
          queryDetail()
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch((res) => {
      message.error(res)
    })
}

const onVerification2Click = (val, record, index) => {
  console.log(record)
  affiliatedCompany = record.affiliatedCompany
}
// 查询核验记录
const onVerificationClick = (val, record, index) => {
  if (val) {
    heyanBenrentelData = []
    if (!record.fullname || !record.id_card_number || !record.phone_number) {
      message.error('请先编辑人员姓名和身份证号码和手机号码')
      return
    }
    verificationLoading.value = true
    telusedminrequired.value = 0
    let data = {
      action: 'heyan_benrentel',
      name: record.fullname,
      idcard: record.id_card_number,
      company_id: props.tableColumnsData.id,
      tel: record.phone_number
    }
    let requestParam = {
      url: '/admin/v1/heyan_benrentel',
      method: 'post',
      data: data
    }
    baseService(requestParam)
      .then((res) => {
        setTimeout(() => {
          telusedminrequired.value = res.data.telusedminrequired
          heyanBenrentelData = res.data.res
          verificationLoading.value = false
        }, 500)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  }
}
// 核验手机号
const onVerificationPhoneClick = (record, index) => {
  let data = {
    tel: record.phone_number,
    cid: props.tableColumnsData.id
  }
  let requestParam = {
    url: '/admin/v1/onlinetimecheck',
    method: 'post',
    data: data
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      queryDetail()
      onVerificationClick(true, record, index)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

// const verificationDisabled = () => {
//   /* 成功 */
//   let successIndex: any = null
//   /* 失败 */
//   let errorIndex: any = null
//   heyanBenrentelData.forEach((element: any, index) => {
//     if (element.resmsg == '三要素身份验证一致') {
//       successIndex = index + 1
//     }
//     if (element.resmsg == '实名核验有误') {
//       errorIndex = index + 1
//     }
//   })
//   if (!successIndex) {
//     return true
//   } else if (successIndex && !errorIndex) {
//     return false
//   } else {
//     if (errorIndex > successIndex) {
//       return false
//     } else {
//       return true
//     }
//   }
// }
</script>

<style lang="less" scoped>
.positionItem {
  display: inline-block;
  margin: 5px 2px;
}

.phone_number {
  min-height: 61px;
  display: inline-block;
  line-height: 61px;
}

.ant-table-content {
  min-height: 230px;
}

.remark {
  display: flex;
  flex-direction: row-reverse;
}
.modal-title {
  display: flex;
  align-items: center;
  .InfoCircleOutlined {
    color: #faad14;
    flex: none;
    margin-inline-end: 12px;
    font-size: 22px;
  }
  .title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }
}
</style>
<style lang="less">
.order-verification-popover {
  width: 750px;

  .ant-popover-inner-content {
    min-height: 300px;
  }

  .ant-popover-title {
    line-height: 40px;
  }
}
</style>
