<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1200"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    :maskClosable="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" scrollToFirstError :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="是否启用" name="status">
          <a-radio-group button-style="solid" v-model:value="formState.status">
            <a-radio-button :value="1">启用</a-radio-button>
            <a-radio-button :value="2">禁用</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="地址编号增长方式" name="numbering_increment_mode">
          <a-radio-group button-style="solid" v-model:value="formState.numbering_increment_mode">
            <a-radio-button :value="1">双轨制</a-radio-button>
            <a-radio-button :value="2">单轨制</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="强制填收货信息" name="is_harvest_info">
          <a-radio-group button-style="solid" v-model:value="formState.is_harvest_info">
            <a-radio-button :value="1">是</a-radio-button>
            <a-radio-button :value="2">否</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="平台名称" name="name">
          <a-input placeholder="请输入平台名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="平台简称" name="platform_abbreviation">
          <a-input placeholder="请输入平台简称" v-model:value="formState.platform_abbreviation"></a-input>
        </a-form-item>
        <a-form-item label="统一社会信用代码" name="social_credit_code">
          <a-input placeholder="请输入统一社会信用代码" v-model:value="formState.social_credit_code"></a-input>
        </a-form-item>
        <a-form-item
          name="latitude_longitude"
          :label="
            h(
              'span',
              {},
              {
                default: () => [
                  h('span', '公司地址经纬度'),
                  h(
                    Tooltip,
                    { title: '点击选取公司地址经纬度' },
                    {
                      default: () => [h(InfoCircleOutlined, { onClick: mapClick, class: ['ml-x'] })]
                    }
                  )
                ]
              }
            )
          "
        >
          <a-input placeholder="请输入公司地址经纬度" v-model:value="formState.latitude_longitude">
            <!-- <template #suffix>
                            <a-tooltip title="点击获取经纬信息">
                                <a-icon @click="mapClick" type="info-circle" style="color: rgba(0, 0, 0, 0.45)" />
                            </a-tooltip>
                        </template> -->
          </a-input>
        </a-form-item>
        <a-form-item label="公司地址省市区" name="company_area_ids">
          <AddressCascader
            v-model:value="formState.company_area_ids"
            @change="
              (res, e) => {
                companyPauaChange(res, e, 'company_detailed_address')
              }
            "
          ></AddressCascader>
        </a-form-item>
        <a-form-item label="公司注册地址" name="company_detailed_address">
          <a-input placeholder="请输入公司注册地址" v-model:value="formState.company_detailed_address"> </a-input>
        </a-form-item>
        <a-form-item label="注册地址匹配关键词">
          <associate-authorization
            v-model:value="formState.company_detailed_address_keywords"
            placeholder="请输入注册地址匹配关键词"
            suffixTitle="回车或失去焦点后可以添加多个关键词"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="公司地址详情介绍" name="company_address" :labelCol="{ span: 7 }" :wrapperCol="{ span: 15 }">
          <Ueditor v-model:value="formState.company_address" uid="u1"></Ueditor>
        </a-form-item>
        <a-form-item label="请输入平台价格详情介绍" name="platform_price_list" :labelCol="{ span: 7 }" :wrapperCol="{ span: 15 }">
          <Ueditor v-model:value="formState.platform_price_list" uid="u2"></Ueditor>
        </a-form-item>
        <a-form-item label="邮寄成功提示语" name="logistics_order_cue">
          <a-textarea v-model:value="formState.logistics_order_cue" placeholder="请输入邮寄成功提示语" :rows="3"></a-textarea>
        </a-form-item>

        <a-form-item label="平台核准日期" name="ratify_time">
          <a-date-picker v-model:value="formState.ratify_time" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="主管机关" name="company_authority">
          <Institution v-model:value="formState.company_authority"></Institution>
        </a-form-item>
        <a-form-item label="公司地址编号前缀">
          <associate-authorization
            v-model:value="formState.company_address_number_prefix"
            placeholder="请输入公司地址编号前缀"
            suffixTitle="回车或失去焦点后可以添加多个公司地址编号前缀"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="公司地址编号后缀">
          <associate-authorization
            v-model:value="formState.company_address_number_postfix"
            placeholder="请输入公司地址编号后缀"
            suffixTitle="回车或失去焦点后可以添加多个公司地址编号后缀"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="个体户地址编号前缀">
          <associate-authorization
            v-model:value="formState.individual_address_number_prefix"
            placeholder="请输入个体户地址编号前缀"
            suffixTitle="回车或失去焦点后可以添加多个个体户地址编号前缀"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="个体户地址编号后缀">
          <associate-authorization
            v-model:value="formState.individual_address_number_postfix"
            placeholder="请输入个体户地址编号后缀"
            suffixTitle="回车或失去焦点后可以添加多个个体户地址编号后缀"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="超级管理员手机号码">
          <associate-authorization
            v-model:value="formState.super_admin_tels"
            placeholder="请输入超级管理员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个超级管理员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="门店负责人手机号码">
          <associate-authorization
            v-model:value="formState.store_manager_tels"
            placeholder="请输入门店负责人手机号码"
            suffixTitle="回车或失去焦点后可以添加多个门店负责人手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="资料员手机号码">
          <associate-authorization
            v-model:value="formState.documenter_tels"
            placeholder="请输入资料员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个资料员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="外勤手机号码">
          <associate-authorization
            v-model:value="formState.field_work_tels"
            placeholder="请输入外勤手机号码"
            suffixTitle="回车或失去焦点后可以添加多个外勤手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="财务手机号码">
          <associate-authorization
            v-model:value="formState.finance_tels"
            placeholder="请输入财务手机号码"
            suffixTitle="回车或失去焦点后可以添加多个财务手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="异常投诉专员手机号码">
          <associate-authorization
            v-model:value="formState.ycts_tels"
            placeholder="请输入异常投诉专员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个异常投诉专员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <div class="form-tips-title">公司类注册代办人信息配置</div>
        <a-form-item label="公司注册代办人姓名" name="company_agent">
          <a-select
            placeholder="请输入公司注册代办人姓名"
            style="width: 100%"
            optionLabelProp="label"
            v-model:value="formState.company_agent"
            show-search
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="
              (val) => {
                handleSearch(val, 'company')
              }
            "
            @change="
              (val, e) => {
                handlSelectChange(e, 'company')
              }
            "
          >
            <a-select-option
              v-for="(optionsItem, optionsIndex) in state.selectCompanyOptions"
              :key="optionsIndex"
              :value="optionsItem.idcard_name"
              :label="optionsItem.idcard_name"
              :title="optionsItem"
            >
              <a-tooltip>
                {{ optionsItem.idcard_name }}
              </a-tooltip>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="公司注册代办人身份证号码" name="company_agent_id_card_number">
          <a-input placeholder="请输入公司注册代办人身份证号码" v-model:value="formState.company_agent_id_card_number"></a-input>
        </a-form-item>
        <a-flex gap="middle" horizontal justify="center">
          <a-flex>
            <a-form-item name="company_agent_id_card_front">
              <a-upload
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                accept=".jpeg,.png,.jpg"
                :before-upload="beforeUpload"
                @change="
                  (info) => {
                    handleChange(info, 'company_agent_id_card_front')
                  }
                "
              >
                <img class="upload-img" type="" v-if="formState.company_agent_id_card_front" :src="$store.state.user.image_domain + formState.company_agent_id_card_front" alt="avatar" />
                <div v-else class="upload-img">
                  <PlusOutlined />
                  <div class="ant-upload-text">上传公司注册代办人人像面照片</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-flex>
          <a-flex>
            <a-form-item name="company_agent_id_card_back">
              <a-upload
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                accept=".jpeg,.png,.jpg"
                :before-upload="beforeUpload"
                @change="
                  (info) => {
                    handleChange(info, 'company_agent_id_card_back')
                  }
                "
              >
                <img class="upload-img" type="" v-if="formState.company_agent_id_card_back" :src="$store.state.user.image_domain + formState.company_agent_id_card_back" alt="avatar" />
                <div v-else class="upload-img">
                  <PlusOutlined />
                  <div class="ant-upload-text">上传公司注册代办人国徽面照片</div>
                </div>
              </a-upload>
            </a-form-item></a-flex
          >
        </a-flex>
        <a-form-item label="公司注册代办人身份证住所" name="company_agent_address">
          <a-input placeholder="请输入公司注册代办人身份证住所" v-model:value="formState.company_agent_address"></a-input>
        </a-form-item>

        <a-form-item label="公司注册代办人工商预留手机号码" name="company_agent_tel">
          <a-input
            @change="
              (v) => {
                phoneChange(v, 'company_agent_tel')
              }
            "
            placeholder="请输入公司注册代办人工商预留手机号码"
            :value="formState.company_agent_tel"
          ></a-input>
        </a-form-item>
        <a-form-item label="公司注册代办人工商账号" name="company_entering_data_account">
          <a-input placeholder="请输入公司注册代办人工商账号" v-model:value="formState.company_entering_data_account"></a-input>
        </a-form-item>
        <a-form-item label="公司注册代办人工商密码" name="company_entering_data_password">
          <a-input placeholder="请输入公司注册代办人工商密码" v-model:value="formState.company_entering_data_password"></a-input>
        </a-form-item>
        <a-form-item label="刻章材料接收人微信" name="kezhang_wxname">
          <a-input placeholder="请输入刻章材料接收人微信" v-model:value="formState.kezhang_wxname"></a-input>
        </a-form-item>
        <a-form-item label="公司注册代办人签字图片模板" name="company_sign_img">
          <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.company_sign_img" uploadText="上传签字模板"> </UploadTmp>
        </a-form-item>
        <div class="form-tips-title">个体户注册代办人信息配置</div>
        <a-form-item label="个体户注册代办人姓名" name="individual_agent">
          <a-select
            placeholder="请输入个体户注册代办人姓名"
            style="width: 100%"
            optionLabelProp="label"
            v-model:value="formState.individual_agent"
            show-search
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="
              (val) => {
                handleSearch(val, 'individual')
              }
            "
            @change="
              (val, e) => {
                handlSelectChange(e, 'individual')
              }
            "
          >
            <a-select-option
              v-for="(optionsItem, optionsIndex) in state.selectIndividualOptions"
              :key="optionsIndex"
              :value="optionsItem.idcard_name"
              :label="optionsItem.idcard_name"
              :title="optionsItem"
            >
              <a-tooltip>
                {{ optionsItem.idcard_name }}
              </a-tooltip>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="个体户注册代办人身份证号码" name="individual_agent_id_card_number">
          <a-input placeholder="请输入个体户注册代办人身份证号码" v-model:value="formState.individual_agent_id_card_number"></a-input>
        </a-form-item>
        <a-flex gap="middle" horizontal justify="center">
          <a-flex>
            <a-form-item name="individual_agent_id_card_front">
              <a-upload
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                accept=".jpeg,.png,.jpg"
                :before-upload="beforeUpload"
                @change="
                  (info) => {
                    handleChange(info, 'individual_agent_id_card_front')
                  }
                "
              >
                <img class="upload-img" type="" v-if="formState.individual_agent_id_card_front" :src="$store.state.user.image_domain + formState.individual_agent_id_card_front" alt="avatar" />
                <div v-else class="upload-img">
                  <PlusOutlined />
                  <div class="ant-upload-text">上传个体户注册代办人人像面照片</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-flex>
          <a-flex>
            <a-form-item name="individual_agent_id_card_back">
              <a-upload
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                accept=".jpeg,.png,.jpg"
                :before-upload="beforeUpload"
                @change="
                  (info) => {
                    handleChange(info, 'individual_agent_id_card_back')
                  }
                "
              >
                <img class="upload-img" type="" v-if="formState.individual_agent_id_card_back" :src="$store.state.user.image_domain + formState.individual_agent_id_card_back" alt="avatar" />
                <div v-else class="upload-img">
                  <PlusOutlined />
                  <div class="ant-upload-text">上传个体户注册代办人国徽面照片</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-flex></a-flex
        >
        <a-form-item label="个体户注册代办人身份证住所" name="individual_agent_address">
          <a-input placeholder="请输入个体户注册代办人身份证住所" v-model:value="formState.individual_agent_address"></a-input>
        </a-form-item>

        <a-form-item label="个体户注册代办人工商预留手机号码" name="individual_agent_tel">
          <a-input
            @change="
              (v) => {
                phoneChange(v, 'individual_agent_tel')
              }
            "
            placeholder="请输入个体户注册代办人工商预留手机号码"
            :value="formState.individual_agent_tel"
          ></a-input>
        </a-form-item>
        <a-form-item label="个体户注册代办人工商账号" name="individual_entering_data_account">
          <a-input placeholder="请输入个体户注册代办人工商账号" v-model:value="formState.individual_entering_data_account"></a-input>
        </a-form-item>
        <a-form-item label="个体户注册代办人工商密码" name="individual_entering_data_password">
          <a-input placeholder="请输入个体户注册代办人工商密码" v-model:value="formState.individual_entering_data_password"></a-input>
        </a-form-item>
        <a-form-item label="个体户注册代办人签字图片模板" name="individual_sign_img">
          <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.individual_sign_img" uploadText="上传签字模板"> </UploadTmp>
        </a-form-item>
        <!-- 公司个人存放地址 -->
        <div class="form-tips-title">运营类相关配置</div>
        <div>
          <a-form-item label="公司执照存放地址(省市区)" name="company_bl_area_ids">
            <AddressCascader
              v-model:value="formState.company_bl_area_ids"
              @change="
                (res, e) => {
                  companyPauaChange(res, e, 'company_bl_address')
                }
              "
            ></AddressCascader>
          </a-form-item>
          <a-form-item label="公司执照存放详细地址" name="company_bl_address">
            <a-input placeholder="请输入公司执照存放详细地址" v-model:value="formState.company_bl_address"></a-input>
          </a-form-item>
          <a-form-item label="个人执照存放地址(省市区)" name="individual_bl_area_ids">
            <AddressCascader
              v-model:value="formState.individual_bl_area_ids"
              @change="
                (res, e) => {
                  companyPauaChange(res, e, 'individual_bl_address')
                }
              "
            ></AddressCascader>
          </a-form-item>

          <a-form-item label="个人执照存放详细地址" name="individual_bl_address">
            <a-input placeholder="请输入个人详细地址" v-model:value="formState.individual_bl_address"></a-input>
          </a-form-item>
        </div>

        <a-form-item label="公司类运营主体名称">
          <a-input placeholder="请输入公司类运营主体名称" v-model:value="formState.company_name"></a-input>
        </a-form-item>

        <a-form-item label="公司类运营主体微信收款appid">
          <a-input placeholder="请输入公司类运营主体微信收款appid" v-model:value="formState.company_wxappid"></a-input>
        </a-form-item>

        <a-form-item label="公司类运营主体微信收款appkey">
          <a-input placeholder="请输入公司类运营主体微信收款appkey" v-model:value="formState.company_wxappkey"></a-input>
        </a-form-item>

        <a-form-item label="个体类运营主体名称">
          <a-input placeholder="请输入个体类运营主体名称" v-model:value="formState.individual_name"></a-input>
        </a-form-item>

        <a-form-item label="个体类运营主体微信收款appid">
          <a-input placeholder="请输入个体类运营主体微信收款appid" v-model:value="formState.individual_wxappid"></a-input>
        </a-form-item>

        <a-form-item label="个体类运营主体微信收款appkey">
          <a-input placeholder="请输入个体类运营主体微信收款appkey" v-model:value="formState.individual_wxappkey"></a-input>
        </a-form-item>
        <div class="form-tips-title">销售价格配置</div>
        <a-form-item label="公司对外销售价格（包注册）" name="company_price_bao">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入公司对外销售价格" v-model:value="formState.company_price_bao"></a-input>
        </a-form-item>
        <a-form-item label="公司对外销售价格（裸地址）" name="company_price_luo">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入公司对外销售价格" v-model:value="formState.company_price_luo"></a-input>
        </a-form-item>
        <a-form-item label="公司地址来年续费价格" name="company_next_renewal_price">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入公司地址来年续费价格" v-model:value="formState.company_next_renewal_price"></a-input>
        </a-form-item>

        <a-form-item label="公司对外销售价格（一次性）" name="company_price_yi">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入公司对外销售价格" v-model:value="formState.company_price_yi"></a-input>
        </a-form-item>

        <a-form-item label="个体户对外销售价格（包注册）" name="individual_price_bao">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入个体户对外销售价格" v-model:value="formState.individual_price_bao"></a-input>
        </a-form-item>
        <a-form-item label="个体户对外销售价格（裸地址）" name="individual_price_luo">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入个体户对外销售价格" v-model:value="formState.individual_price_luo"></a-input>
        </a-form-item>
        <a-form-item label="个体户地址来年续费价格" name="individual_next_renewal_price">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入个体户地址来年续费价格" v-model:value="formState.individual_next_renewal_price"></a-input>
        </a-form-item>

        <a-form-item label="个体户对外销售价格（一次性）" name="individual_price_yi">
          <a-input type="number" style="width: 400px" addon-after="元" placeholder="请输入个体户对外销售价格" v-model:value="formState.individual_price_yi"></a-input>
        </a-form-item>
        <div class="form-tips-title">限制、风控、平台属性配置</div>
        <a-form-item label="限制公司注册时间">
          <range-picker v-model:value="formState.company_register_time" />
        </a-form-item>
        <a-form-item label="限制个体注册时间">
          <range-picker v-model:value="formState.individual_register_time" />
        </a-form-item>
        <a-form-item label="我公司需核验法代真实号码">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.company_number_verification"></a-switch>
        </a-form-item>
        <a-form-item label="核验手机号码时间最低要求">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.verification_phone_time_code"></a-switch>
        </a-form-item>
        <a-form-item v-if="formState.verification_phone_time_code == 1" label="核验手机号码时间">
          <a-input style="width: 200px" addonAfter="月" v-model:value="formState.verification_phone_time"></a-input>
        </a-form-item>
        <a-form-item label="同负责人、同法人代表同时注册的数量">
          <a-input style="width: 200px" addonAfter="个" v-model:value="formState.register_number"></a-input>
        </a-form-item>
        <a-form-item label="是否有营业执照">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.ishave_business_license"></a-switch>
        </a-form-item>
        <a-form-item label="是否开放一次性地址">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.disposable_switch"></a-switch>
        </a-form-item>

        <a-form-item label="是否有章">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.ishave_seal"></a-switch>
        </a-form-item>

        <a-form-item label="平台模式">
          <a-radio-group button-style="solid" v-model:value="formState.platform_model">
            <a-radio-button :value="1">自营</a-radio-button>
            <a-radio-button :value="2">合作</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="业务对接人电话" name="business_contact_tel" :required="formState.platform_model == 2">
          <associate-authorization
            v-model:value="formState.business_contact_tel"
            placeholder="请输入业务对接人电话"
            suffixTitle="回车或失去焦点后可以添加多个业务对接人电话"
            inputType="number"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="最大允许编号" name="maximum_number">
          <a-input placeholder="请输入最大允许编号" v-model:value="formState.maximum_number"></a-input>
        </a-form-item>
        <a-form-item label="是否提供裸地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.is_provide_adds"></a-switch>
        </a-form-item>
        <a-form-item label="是否提供一次性地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.is_provide_ycx"></a-switch>
        </a-form-item>
        <a-form-item label="公司类主体后加字">
          <a-input placeholder="请输入公司类主体后加字" v-model:value="formState.gs_add_words"></a-input>
        </a-form-item>
        <a-form-item label="个体类主体后加字">
          <a-input placeholder="请输入个体类主体后加字" v-model:value="formState.gt_add_words"></a-input>
        </a-form-item>
        <a-form-item label="入驻协议乙方姓名">
          <a-radio-group v-model:value="formState.yifang_name_type">
            <a-radio :value="1">主体名称</a-radio>
            <a-radio :value="2">主体法人代表或负责人姓名</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="平台公章" name="official_seal">
          <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.official_seal" uploadText="上传平台公章"> </UploadTmp>
        </a-form-item>
        <div class="form-tips-title">电子化模板</div>
        <a-form-item label="选择公司电子化核名承诺书模板">
          <select-template v-model:value="formState.company_online_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择公司电子化地址资料模板" name="company_online_templatedz_ids">
          <select-template v-model:value="formState.company_online_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择公司电子化其他资料模板">
          <select-template v-model:value="formState.company_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个体电子化核名承诺书模板">
          <select-template v-model:value="formState.individual_online_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个体电子化地址资料模板" name="individual_online_templatedz_ids">
          <select-template v-model:value="formState.individual_online_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个体电子化其他资料模板">
          <select-template v-model:value="formState.individual_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个独电子化核名承诺书模板">
          <select-template v-model:value="formState.proprietorships_online_templatedz_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个独电子化地址资料模板" name="proprietorships_online_templatecn_ids">
          <select-template v-model:value="formState.proprietorships_online_templatecn_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个独电子化其他资料模板">
          <select-template v-model:value="formState.proprietorships_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>

        <div class="form-tips-title">现场模板</div>
        <a-form-item label="选择公司现场核名承诺书模板">
          <select-template v-model:value="formState.company_scene_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择公司现场地址资料模板" name="company_scene_templatedz_ids">
          <select-template v-model:value="formState.company_scene_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择公司现场其他资料模板">
          <select-template v-model:value="formState.company_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个体现场核名承诺书模板">
          <select-template v-model:value="formState.individual_scene_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个体现场地址资料模板" name="individual_scene_templatedz_ids">
          <select-template v-model:value="formState.individual_scene_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个体现场其他资料模板">
          <select-template v-model:value="formState.individual_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个独现场核名承诺书模板">
          <select-template v-model:value="formState.proprietorships_scene_templatedz_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个独现场地址资料模板" name="proprietorships_scene_templatecn_ids">
          <select-template v-model:value="formState.proprietorships_scene_templatecn_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个独现场其他资料模板">
          <select-template v-model:value="formState.proprietorships_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>

        <div class="form-tips-title">银行开户资料</div>
        <a-form-item label="选择公司给客户模板">
          <select-template v-model:value="formState.company_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="选择个体户给客户模板">
          <select-template v-model:value="formState.individual_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="选择个人独资企业给客户模板">
          <select-template v-model:value="formState.proprietorships_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
        <div class="form-tips-title">其他模板</div>
        <a-form-item label="续费合同">
          <select-template v-model:value="formState.xufeihetong_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="催款告知书">
          <select-template v-model:value="formState.xufeigaozhishu_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="解除入驻合同模版">
          <select-template v-model:value="formState.jiechuhetong_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="列入异常模板">
          <select-template v-model:value="formState.lieruyichang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移出地址异常合同模版">
          <select-template v-model:value="formState.yichuyichang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="工单汇报模板">
          <select-template v-model:value="formState.ticketreporting_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="刻章模板">
          <select-template v-model:value="formState.kezhang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
        <div class="form-tips-title">案件线索移送函专区</div>
        <a-form-item label="移送阿里巴巴模板">
          <select-template v-model:value="formState.yisong_alibaba_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送淘宝模板">
          <select-template v-model:value="formState.yisong_taobao_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送抖音模板">
          <select-template v-model:value="formState.yisong_douyin_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送拼多多模板">
          <select-template v-model:value="formState.yisong_pinduoduo_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
      </a-form>
    </a-spin>
    <image-cropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :isId="false"
      :upload_path="`public/uploads/${state.company_detailed_address}/${formState.name}/平台资料/`"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      @change="cropperChange"
      v-if="state.showCropper"
    ></image-cropper>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, h } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import ImageCropper from '@/components/ImageCropper/index.vue'
import dayjs from 'dayjs'
import Institution from './Institution.vue'
import cloneDeep from 'lodash.clonedeep'
import SelectTemplate from './SelectTemplate.vue'
import Ueditor from '@/components/Ueditor/index.vue'
import { Tooltip } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { AddressCascader, AssociateAuthorization, RangePicker } from '@/components'
import { useStore } from 'vuex'
import UploadTmp from '@/components/base/UploadTmp.vue'
const $store = useStore()
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const templateObj = reactive<any>({
  // 全部
  all: [],
  // 承诺书
  letterOfCommitment: [],
  // 地址资料
  address: [],
  // 其他资料
  other: []
})

let timer: any = null
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const checkAuthority = (_rule: any, value: any) => {
  if (!value) {
    return Promise.reject('请输入机关')
  }
  if (!value.glj1) {
    return Promise.reject('请输入市机关')
  }
  if (!value.glj2) {
    return Promise.reject('请输入区机关')
  }
  if (!value.jd) {
    return Promise.reject('请输入街道机关')
  }
  if (!value.gls) {
    return Promise.reject('请输入所机关')
  }
  return Promise.resolve()
}
const rules = {
  name: [{ required: true, message: '请输入平台名称' }],
  platform_abbreviation: [{ required: true, message: '请输入平台简称' }],
  social_credit_code: [{ required: true, message: '请输入统一社会信用代码' }],
  latitude_longitude: [{ required: true, message: '请输入公司地址经纬度' }],
  company_area_ids: [{ required: true, message: '请选择公司地址省市区' }],
  company_detailed_address: [{ required: true, message: '请输入公司详细地址' }],
  company_address: [{ required: true, message: '请输入公司地址详情介绍' }],
  platform_price_list: [{ required: false, message: '请输入平台价格详情介绍' }],
  ratify_time: [{ required: true, message: '请选择批准核准时间' }],
  company_authority: [{ required: true, validator: checkAuthority }],
  company_agent: [{ required: true, message: '请输入公司代办人姓名' }],
  company_entering_data_account: [{ required: true, message: '请输入公司录资料账号' }],
  company_entering_data_password: [{ required: true, message: '请输入公司录资料密码' }],
  individual_agent: [{ required: true, message: '请输入个体代办人姓名' }],
  individual_entering_data_account: [{ required: true, message: '请输入个体录资料账号' }],
  individual_entering_data_password: [{ required: true, message: '请输入个体录资料密码' }],
  company_bl_area_ids: [{ required: true, message: '请选择公司执照存放地址' }],
  company_bl_address: [{ required: true, message: '请输入公司执照存放详细地址' }],
  individual_bl_area_ids: [{ required: true, message: '请选择个人执照存放地址' }],
  individual_bl_address: [{ required: true, message: '请输入个人执照存放详细地址' }],
  company_price_bao: [{ required: true, message: '请输入公司对外销售价格' }],
  company_price_luo: [{ required: true, message: '请输入公司对外销售价格' }],
  company_price_yi: [{ required: true, message: '请输入公司对外销售价格' }],
  individual_price_bao: [{ required: true, message: '请输入个体户对外销售价格' }],
  individual_price_luo: [{ required: true, message: '请输入个体户对外销售价格' }],
  individual_price_yi: [{ required: true, message: '请输入个体户对外销售价格' }],
  company_register_time: [{ required: true, message: '请选择限制公司注册时间' }],
  individual_register_time: [{ required: true, message: '请选择限制个体注册时间' }],
  maximum_number: [{ required: true, message: '请输入最大允许编号' }],
  yifang_name_type: [{ required: true, message: '请选择入驻协议乙方姓名类型' }],

  individual_next_renewal_price: [{ required: true, message: '请输入个体户地址来年续费价格' }],
  company_next_renewal_price: [{ required: true, message: '请输入公司地址来年续费价格' }],

  individual_agent_id_card_front: [{ required: true, message: '请上传个体代办人身份证人像面照片' }],
  individual_agent_id_card_back: [{ required: true, message: '请上传个体代办人身份证国徽面照片' }],
  individual_agent_tel: [{ required: true, message: '请输入个体代办人工商预留手机号码' }],
  individual_agent_address: [{ required: true, message: '请输入个体户注册代办人身份证住所' }],
  individual_agent_id_card_number: [{ required: true, message: '请输入个体代办人身份证号码' }],
  company_agent_id_card_front: [{ required: true, message: '请上传公司代办人身份证人像面照片' }],
  company_agent_id_card_back: [{ required: true, message: '请上传公司代办人身份证国徽面照片' }],
  company_agent_tel: [{ required: true, message: '请输入公司代办人工商预留手机号码' }],
  company_agent_address: [{ required: true, message: '请输入公司注册代办人身份证住所' }],
  company_agent_id_card_number: [{ required: true, message: '请输入公司代办人身份证号码' }],
  official_seal: [{ required: true, message: '请上传平台公章' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  showCropper: boolean
  imageUrl: string
  imageName: string
  currentUpload: string
  company_detailed_address: string
  selectIndividualOptions: any[]
  selectCompanyOptions: any[]
}
const state = reactive<state>({
  modalTitle: '新增平台',
  loading: false,
  showCropper: false,
  imageUrl: '',
  imageName: '',
  currentUpload: '',
  company_detailed_address: '',
  selectCompanyOptions: [],
  selectIndividualOptions: []
})

interface formState {
  name: string
  yifang_name_type: number
  company_detailed_address_keywords: string
  status: number
  register_number: number
  is_harvest_info: number
  numbering_increment_mode: number
  platform_abbreviation: string
  social_credit_code: string
  latitude_longitude: string
  company_area_ids: string
  company_address: string
  platform_price_list: string
  company_detailed_address: string
  ratify_time: string
  company_agent: string | null
  company_entering_data_account: string
  company_entering_data_password: string
  individual_agent: string | null
  individual_entering_data_account: string
  individual_entering_data_password: string
  company_bl_area_ids: string
  company_bl_address: string
  individual_bl_area_ids: string
  individual_bl_address: string
  maximum_number: string
  company_online_templatedz_ids: string
  individual_online_templatedz_ids: string
  company_scene_templatedz_ids: string
  individual_scene_templatedz_ids: string
  company_name: string | undefined
  company_wxappid: string | undefined
  company_wxappkey: string | undefined
  individual_name: string | undefined
  individual_wxappid: string | undefined
  individual_wxappkey: string | undefined
  verification_phone_time_code: number | undefined
  ishave_seal: number | undefined
  ishave_business_license: number | undefined
  company_online_templatecn_ids: string | undefined
  company_online_templateqt_ids: string | undefined
  individual_online_templatecn_ids: string | undefined
  individual_online_templateqt_ids: string | undefined
  proprietorships_online_templateqt_ids: string | undefined
  proprietorships_online_templatecn_ids: string | undefined
  proprietorships_online_templatedz_ids: string | undefined
  company_scene_templatecn_ids: string | undefined
  company_scene_templateqt_ids: string | undefined
  individual_scene_templatecn_ids: string | undefined
  individual_scene_templateqt_ids: string | undefined
  proprietorships_scene_templatedz_ids: string | undefined
  proprietorships_scene_templatecn_ids: string | undefined
  proprietorships_scene_templateqt_ids: string | undefined
  company_client_template_ids: string | undefined
  individual_client_template_ids: string | undefined
  proprietorships_client_template_ids: string | undefined
  xufeihetong_template_ids: string | undefined
  xufeigaozhishu_template_ids: string | undefined
  company_price_bao: number | undefined
  company_price_luo: number | undefined
  company_price_yi: number | undefined
  individual_price_bao: number | undefined
  individual_price_luo: number | undefined
  individual_next_renewal_price: number | undefined
  company_next_renewal_price: number | undefined
  individual_price_yi: number | undefined
  company_number_verification: number
  verification_phone_time: number | null
  is_provide_adds: number
  platform_model: number
  is_provide_ycx: number
  company_register_time: string | undefined
  individual_register_time: string | undefined
  logistics_order_cue: string | undefined
  company_address_number_prefix: string | undefined
  company_address_number_postfix: string | undefined
  individual_address_number_prefix: string | undefined
  business_contact_tel: string | undefined
  field_work_tels: string | undefined
  finance_tels: string | undefined
  documenter_tels: string | undefined
  store_manager_tels: string | undefined
  super_admin_tels: string | undefined
  individual_address_number_postfix: string | undefined
  company_authority: object
  company_agent_id_card_front: string
  company_agent_id_card_back: string
  company_agent_tel: string
  company_agent_address: string
  individual_agent_id_card_front: string
  individual_agent_id_card_back: string
  individual_agent_tel: string
  individual_agent_address: string
  individual_agent_id_card_number: string
  company_agent_id_card_number: string
  jiechuhetong_template_ids: string
  yichuyichang_template_ids: string
  ticketreporting_template_ids: string
  lieruyichang_template_ids: string
  disposable_switch: number
  gs_add_words: string
  gt_add_words: string
  ycts_tels: string | undefined
  official_seal: string | undefined
  company_sign_img: string | undefined
  individual_sign_img: string | undefined
  yisong_alibaba_ids: string | undefined
  yisong_taobao_ids: string | undefined
  yisong_douyin_ids: string | undefined
  yisong_pinduoduo_ids: string | undefined
  kezhang_template_ids: string | undefined
  kezhang_wxname: string | undefined
}
let form = ref<formState>({
  company_detailed_address_keywords: '',
  status: 1,
  yifang_name_type: 1,
  register_number: 2,
  numbering_increment_mode: 2,
  is_harvest_info: 1,
  name: '',
  platform_abbreviation: '',
  social_credit_code: '',
  latitude_longitude: '',
  company_area_ids: '',
  company_address: '',
  platform_price_list: '',
  logistics_order_cue: '',
  company_detailed_address: '',
  ratify_time: '',
  is_provide_ycx: 1,
  company_agent: null,
  company_entering_data_account: '',
  company_entering_data_password: '',
  individual_agent: null,
  individual_entering_data_account: '',
  individual_entering_data_password: '',
  company_bl_area_ids: '',
  company_bl_address: '',
  individual_bl_area_ids: '',
  individual_bl_address: '',
  maximum_number: '',
  company_online_templatedz_ids: '',
  individual_online_templatedz_ids: '',
  company_scene_templatedz_ids: '',
  individual_scene_templatedz_ids: '',
  company_name: undefined,
  company_wxappid: undefined,
  company_wxappkey: undefined,
  individual_name: undefined,
  individual_wxappid: undefined,
  individual_wxappkey: undefined,
  verification_phone_time_code: 2,
  ishave_seal: 2,
  ishave_business_license: 2,
  company_online_templatecn_ids: undefined,
  company_online_templateqt_ids: undefined,
  individual_online_templatecn_ids: undefined,
  individual_online_templateqt_ids: undefined,
  proprietorships_online_templateqt_ids: undefined,
  proprietorships_online_templatecn_ids: undefined,
  proprietorships_online_templatedz_ids: undefined,
  company_scene_templatecn_ids: undefined,
  company_scene_templateqt_ids: undefined,
  individual_scene_templatecn_ids: undefined,
  individual_scene_templateqt_ids: undefined,
  proprietorships_scene_templatedz_ids: undefined,
  proprietorships_scene_templatecn_ids: undefined,
  proprietorships_scene_templateqt_ids: undefined,
  company_client_template_ids: undefined,
  individual_client_template_ids: undefined,
  proprietorships_client_template_ids: undefined,
  xufeihetong_template_ids: undefined,
  xufeigaozhishu_template_ids: undefined,
  company_price_bao: undefined,
  company_price_luo: undefined,
  company_price_yi: undefined,
  individual_price_bao: undefined,
  individual_price_luo: undefined,
  individual_next_renewal_price: undefined,
  company_next_renewal_price: undefined,
  individual_price_yi: undefined,
  company_number_verification: 2,
  verification_phone_time: 1,
  is_provide_adds: 1,
  platform_model: 1,
  company_register_time: undefined,
  individual_register_time: undefined,
  company_address_number_prefix: undefined,
  company_address_number_postfix: undefined,
  individual_address_number_prefix: undefined,
  business_contact_tel: undefined,
  individual_address_number_postfix: undefined,
  field_work_tels: undefined,
  finance_tels: undefined,
  ycts_tels: undefined,
  documenter_tels: undefined,
  store_manager_tels: undefined,
  super_admin_tels: undefined,
  company_authority: {},
  company_agent_id_card_front: '',
  company_agent_id_card_back: '',
  company_agent_tel: '',
  company_agent_address: '',
  individual_agent_id_card_front: '',
  individual_agent_id_card_back: '',
  individual_agent_tel: '',
  individual_agent_address: '',
  individual_agent_id_card_number: '',
  company_agent_id_card_number: '',
  jiechuhetong_template_ids: '',
  yichuyichang_template_ids: '',
  ticketreporting_template_ids: '',
  lieruyichang_template_ids: '',
  disposable_switch: 1,
  gs_add_words: '',
  gt_add_words: '',
  official_seal: '',
  company_sign_img: '',
  individual_sign_img: '',
  yisong_alibaba_ids: '',
  yisong_taobao_ids: '',
  yisong_douyin_ids: '',
  yisong_pinduoduo_ids: '',
  kezhang_template_ids: '',
  kezhang_wxname: ''
})
let formState = form.value

const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
}
onMounted(() => {
  if (props.detailData?.id) {
    queryDetail(props.detailData.id)
    state.modalTitle = '编辑平台'
  }
  queryTemplate('all')
  queryTemplate(1)
  queryTemplate(2)
  queryTemplate(3)
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/platform/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    res.data.ratify_time = dayjs(res.data.ratify_time, 'YYYY-MM-DD')
    form.value = res.data
    formState = form.value
    state.company_detailed_address = res.data.comoany_area_ssq
  })
}
// 查询模板
const queryTemplate = async (type) => {
  let requestParam = {
    url: '/admin/v1/templateList',
    method: 'post',
    data: Object.assign({
      limit: 'all',
      template_type: type === 'all' ? null : type
    })
  }
  await baseService(requestParam).then((res) => {
    switch (type) {
      case 1:
        templateObj.address = [...res.data.list]
        break
      case 2:
        templateObj.letterOfCommitment = [...res.data.list]
        break
      case 3:
        templateObj.other = [...res.data.list]
        break
      case 'all':
        templateObj.all = [...res.data.list]
        break
    }
  })
}

const handlSelectChange = (e, type) => {
  let obj = e.title.filings_info
  if (e.title.diy) {
    return
  }
  if (type === 'company') {
    formState.company_entering_data_password = e.title.dzh_password
    formState.company_entering_data_account = e.title.dzh_name
    formState.company_agent_tel = obj.customer_tel
    formState.company_agent_address = obj.identity_card_address
    formState.company_agent_id_card_back = obj.identity_card_contrary
    formState.company_agent_id_card_front = obj.identity_card_front
    formState.company_agent_id_card_number = obj.identity_card_number
  } else {
    formState.individual_entering_data_password = e.title.dzh_password
    formState.individual_entering_data_account = e.title.dzh_name
    formState.individual_agent_tel = obj.customer_tel
    formState.individual_agent_address = obj.identity_card_address
    formState.individual_agent_id_card_back = obj.identity_card_contrary
    formState.individual_agent_id_card_front = obj.identity_card_front
    formState.individual_agent_id_card_number = obj.identity_card_number
  }
}

const handleSearch = (e, type) => {
  clearTimeout(timer)
  if (e == '') {
    if (type == 'company') {
      state.selectCompanyOptions = []
    } else {
      state.selectIndividualOptions = []
    }
    return
  }
  timer = setTimeout(() => {
    let requestParam = {
      url: '/admin/userCheckShareFilings?idcard_name=' + e,
      method: 'get'
    }
    baseService(requestParam)
      .then((res) => {
        if (!res.data.some((res) => res.idcard_name === e)) {
          res.data.unshift({
            idcard_name: e,
            diy: true
          })
        }
        if (type == 'company') {
          state.selectCompanyOptions = res.data
        } else {
          state.selectIndividualOptions = res.data
        }
      })
      .catch((res) => {
        if (type == 'company') {
          state.selectCompanyOptions = []
        } else {
          state.selectIndividualOptions = []
        }
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('查询失败')
        }
      })
  }, 500)
}

// 获取经纬度
const mapClick = () => {
  window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html')
}
// 省市区 选择和替换
const companyPauaChange = (res, e, type) => {
  if (type == 'company_detailed_address') {
    state.company_detailed_address = `${e[0].name}${e[1].name}${e[2].name}`
  }
  let address = `${e[0].name}${e[1].name}${e[2].name}`
  formState[type] = address
}
// 提交
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      let form = cloneDeep(formState)
      form.ratify_time = dayjs(formState.ratify_time).format('YYYY-MM-DD')
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/platform/' + props.detailData.id,
          method: 'put',
          data: form
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/platform',
          method: 'post',
          data: form
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      }
    })
    .catch(() => {
      message.warning('请检查表单必填项')
    })
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片必须小于2MB!')
  }
  return false
}

// 图片上传
const handleChange = (info, type) => {
  if (!formState.name || formState.name.length === 0) {
    message.warn('请填写平台名称')
    return
  }
  if (!state.company_detailed_address || state.company_detailed_address.length === 0) {
    message.warn('请选择平台地址')
    return
  }
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}
// 裁剪成功
const cropperChange = (res) => {
  formState[state.currentUpload] = res.url
  state.showCropper = false
  ruleForm.value.validate(state.currentUpload)
}
</script>

<style lang="less" scoped>
.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}
</style>
